import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  TextContainer,
  ErrorHeading,
  ErrorText,
  RedirectButton,
  ButtonContainer,
  ImageContainer,
  Image,
  ErrorSubText,
  ErrorMobileText,
} from "./error-page-styles";
import { errorImage } from "../../assets/images";
export const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <TextContainer>
        <ErrorHeading>Oops! </ErrorHeading>
        <ErrorMobileText>
          {" "}
          Error #404 page not found{" "}
        </ErrorMobileText>
        <ErrorText>
          {" "}
          We can't seem to find the page you're looking for{" "}
        </ErrorText>
        <ErrorSubText> Click one of these helpful links below to get back on track</ErrorSubText>
        <ButtonContainer>
          <RedirectButton onClick={() => navigate("/home")}>
            Home
          </RedirectButton>
          <RedirectButton onClick={() => navigate("/contact")}>
            Contact
          </RedirectButton>
        </ButtonContainer>
      </TextContainer>
      <ImageContainer>
        <Image src={errorImage} alt="error page icon" />
      </ImageContainer>
    </Container>
  );
};
