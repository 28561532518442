import React, { useState, useEffect } from "react";
import { closeIcon, hamburgerMenu, logo } from "../../assets/images";
import { MobileNavMenuLinks } from "./mobile-nav-links";
import { NavProps } from "./nav-bar-types";
import { useLocation } from "react-router-dom";
import { LogoContainer, Logo, HamburgerMenu, NavBar } from "./navbar-styles";

export const MobileNavigation = ({ className }: NavProps) => {
  const [hamburgerMenuIsOpen, setHamburgerMenuIsOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setHamburgerMenuIsOpen(false);
  }, [location]);

  const handleMenuClick = () => {
    setHamburgerMenuIsOpen(!hamburgerMenuIsOpen);
  };
  return (
    <>
      <NavBar className={className}>
        <LogoContainer href="/">
          <Logo src={logo} alt="Blaq byte logo" />
        </LogoContainer>
        <HamburgerMenu
          src={hamburgerMenuIsOpen ? closeIcon : hamburgerMenu}
          alt={hamburgerMenuIsOpen ? "close icon" : "hamburger menu icon"}
          onClick={handleMenuClick}
        />
      </NavBar>
      {hamburgerMenuIsOpen && <MobileNavMenuLinks />}
    </>
  );
};
