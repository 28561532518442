import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { motion, MotionProps } from "framer-motion";

export const NavBarContainer = styled.div``;
export const MobileNavContainer = styled.div`
  background: #1b1a17;
  animation: fadeIn 5s;
  @media screen and (min-width: 787px) {
    display: none;
  }
`;

export const NavBar = styled.nav`
  width: 100vw;
  height: auto;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  background: #1b1a17;
  padding: 8px 20px;
  align-items: center;
  font-weight: 500;

  &.mobileNav {
    display: none;
  }

  @media screen and (max-width: 787px) {
    &.normalNav {
      display: none;
    }

    &.mobileNav {
      display: flex;
      // background-color: green;
    }
  }
`;

export const LogoContainer = styled.a``;

export const Logo = styled.img`
  width: 70px;
  height: 70px;
`;

export const HamburgerMenu = styled.img`
  cursor: pointer;
`;

export const NavLinks = styled.ul`
  display: flex;
  margin-right: 12px;
  align-items: center;
`;

export const MobileNavLinks = styled.ul`
  all: unset;
  display: flex;
  flex-direction: column;
  padding: 16px;
  font-size: 24px;
`;

export const NavLinkItem = styled(NavLink)`
  text-decoration: none;
  list-style: none;
  color: white;
  margin: 0 16px;
  cursor: pointer;
  align-self: center;

  @media screen and (max-width: 787px) {
    display: none;
  }

  &:hover,
  &.active {
    color: #c65707;
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  list-style: none;
  color: white;
  margin: 16px;
  cursor: pointer;
  align-self: flex-end;

  &.active {
    color: #c65707;
  }

  @media screen and (max-width: 502px) {
    align-self: center;
  }
`;

interface NavLinkProps extends MotionProps {
  to?: string;
}

export const MobileNavLinkItem = motion<NavLinkProps>(StyledNavLink);

export const StyledMenuItem = styled.a`
  text-decoration: none;
  list-style: none;
  color: white;
  margin: 16px;
  cursor: pointer;
  align-self: flex-end;

  &.active {
    color: #c65707;
  }

  @media screen and (max-width: 502px) {
    align-self: center;
  }
`;

export const MenuItem = motion(StyledMenuItem);

export const StyledButton = styled.a`
  all: unset;
  width: fit-content;
  height: fit-content;
  padding: 8px 20px;
  border: none;
  box-shadow: 0px 10px -14px 14px #fff;
  font-weight: 400px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 200px;
  background: #c65707;
  color: #fff;
  cursor: pointer;
  margin-left: 12px;

  &:hover {
    background: #d97700;
  }
  box-shadow: none;
  float: right;

  @media screen and (max-width: 787px) {
    align-self: flex-end;
    margin: 16px;
    font-size: 24px;
  }

  @media screen and (max-width: 502px) {
    align-self: center;
  }
`;

export const HeaderButton = motion(StyledButton);
