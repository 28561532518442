import styled from "styled-components";

const FormContainer = styled.form`
  display: flex;
  justify-content: space-evenly;
  background-color: #ededec;
  color: #031f48;

  @media screen and (max-width: 590px) {
    display: block;
    padding: 24px;
  }
`;

const Fieldset = styled.fieldset`
  border: none;
`;
const LeftFormContainer = styled.div`
  display: block;
  width: 70%;
  padding: 24px;

  @media screen and (max-width: 590px) {
    padding: 0;
    margin-bottom: 24px;
    width: 100%;
  }
`;

const RightFormContainer = styled.div`
  display: block;
  width: 100%;
  padding: 24px;
  @media screen and (max-width: 590px) {
    padding: 0;
  }
`;

const Legend = styled.legend`
  visibility: hidden;
`;

const Input = styled.input`
  width: 100%;
  height: 32px;
  margin-bottom: 8px;
  border: none;
  padding: 4px;
  font-size: 14px;
  box-sizing: border-box;
  padding-left: 12px;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 300px;
  font-family: inherit;
  font-size: 14px;
  margin-bottom: 8px;
  border: none;
  box-sizing: border-box;
  padding: 12px 0 0 12px;
  resize: none;
  font-size: 14px;
`;

const SubmitButton = styled.button`
  width: fit-content;
  height: fit-content;
  padding: 8px 20px;
  font-weight: 400px;
  border-radius: 4px;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 200px;
  background: #c65707;
  cursor: pointer;

  &:hover {
    background: #d97700;
  }
  float: right;
`;

const CheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const CheckboxHeading = styled.h2``;

const CheckboxInputContainer = styled.div`
  display: flex;
`;

const CheckboxLabel = styled.label`
  font-size: 16px;
  padding-left: 12px;
`;
const CheckboxInput = styled.input``;

export {
  FormContainer,
  LeftFormContainer,
  RightFormContainer,
  Fieldset,
  SubmitButton,
  TextArea,
  Input,
  Legend,
  CheckboxContainer,
  CheckboxHeading,
  CheckboxInput,
  CheckboxInputContainer,
  CheckboxLabel,
};
