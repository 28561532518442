import React, { useEffect, useState } from "react";
import "../index.css";
import { Route, Routes } from "react-router-dom";
import { About, Contact, Home, LoadingSpinner } from "../pages";
import { Navbar } from "../components/navbar/navbar";
import { Footer } from "../components/footer";
import {Error} from "../pages/Error"
  

export const App = () => {
  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    setTimeout(() => setSpinner(false), 1500);
  }, []);

  return (
    <>
      {spinner ? (
        <>
          <LoadingSpinner />
          <Footer footerText="Blaq Byte Ltd © 2023" />
        </>
      ) : (
        <div className="App">
          <Navbar
          />
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/home" element={<Home/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/about" element={<About/>} />            
            {/* <Route path="/partner" element={<Partner/>} />             */}
            <Route path="*" element={<Error/>} />            
          </Routes>
          <Footer footerText="Blaq Byte Ltd © 2023" />
        </div>
      )}
    </>
  );
};

export default App;
