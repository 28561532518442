import React from "react";
import {
  HeaderButton,
  MobileNavLinks,
  MobileNavLinkItem as NavLink,
  MobileNavContainer,
  MenuItem,
} from "./navbar-styles";

export const MobileNavMenuLinks = () => {
  const animateFrom = { opacity: 1, y: -20 };
  const animateTo = { opacity: 1, y: 0 };

  return (
    <MobileNavContainer>
      <MobileNavLinks>
        <NavLink
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.05 }}
          to="/home"
        >
          Home
        </NavLink>
        <NavLink
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.1 }}
          to="/about"
        >
          About
        </NavLink>
        <MenuItem
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.15 }}
          href="https://calendly.com/blaqbyte/1-1-get-into-tech-mentorship?month=2023-02"
          target="_blank"
        >
          Book a consultation
        </MenuItem>
        <NavLink
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.2 }}
          to="/contact"
        >
          Contact
        </NavLink>
        {/* <NavLink
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.2 }}
          to="/partner"
        >
          Partner
        </NavLink> */}
        <HeaderButton
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.25 }}
          href="https://docs.google.com/forms/d/e/1FAIpQLSc9ns9a3GUxYOArffsi0PbJAKZEwCGUUU6nwtl_O4ngBl66dw/viewform"
          target="_blank"
        >
          Join community
        </HeaderButton>
      </MobileNavLinks>
    </MobileNavContainer>
  );
};
