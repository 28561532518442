import React from "react";
import { Card } from "../../components/card";
import { PageContent } from "../page-content";
import { TextBlockContent } from "../text-block-content";
import { PageContainer } from "./about-page-styles";
import { AboutPageProps } from "./about-page-types";

export const AboutPage = ({
  bannerText,
  textHeading,
  firstText,
  secondText,
  thirdText,
  imageSrc,
  leftText,
  leftTextHeading,
  rightTextHeading,
  rightText,
  cards,
  sectionHeading
}: AboutPageProps) => (
  <>
    <PageContent
      bannerText={bannerText}
      textHeading={textHeading}
      firstText={firstText}
      secondText={secondText}
      thirdText={thirdText}
      imageSrc={imageSrc}
    />
    <PageContainer>
      <TextBlockContent
        rightTextHeading={rightTextHeading}
        leftTextHeading={leftTextHeading}
        leftText={leftText}
        rightText={rightText}
      />
    </PageContainer>
    <Card cards={cards} sectionHeading={sectionHeading} />
  </>
);
