import React from "react";
import { MobileNavigation } from "./mobile-nav-menu";
import { NavBarContainer } from "./navbar-styles";
import { Navigation } from "./navigation";

export const Navbar = () => {
  return (
    <NavBarContainer>
      <MobileNavigation className="mobileNav" />
      <Navigation className="normalNav" />
    </NavBarContainer>
  );
};
