import React from "react";
import {
  TextContainer,
  TextHeading,
  TextSubheading,
} from "./text-section-styles";
import { TextProps } from "./text-section-types";

export const TextSection = ({
  textHeading,
  subContentText,
  textColor,
  textPadding,
  textPaddingLeft,
  backgroundColor,
  leftWidth,
}: TextProps) => {
  return (
    <TextContainer
      style={{
        color: `${textColor}`,
        padding: `${textPadding}`,
        backgroundColor: `${backgroundColor}`,
        paddingLeft: `${textPaddingLeft}`,
        width: `${leftWidth}`,
      }}
    >
      <TextHeading>{textHeading}</TextHeading>
      <TextSubheading>{subContentText}</TextSubheading>
    </TextContainer>
  );
};
