import styled from "styled-components";

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  color: #1b1a17;
  margin-bottom: 36px;
  padding: 24px;

  @media screen and (max-width: 826px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 42px;
  }
`;
