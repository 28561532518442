import styled from "styled-components";

export const TextContainer = styled.div`
  display: block;
  width: 50%;

  @media screen and (max-width: 826px) {
    width: 100%;
  }
`;

export const TextHeading = styled.h1`
  font-size: 2em;
  font-weight: 400px;
  @media screen and (max-width: 826px) {
    font-size: 28px;
  }
`;

export const TextSubheading = styled.p`
  font-size: 24px;
  @media screen and (max-width: 826px) {
    font-size: 18px;
  }
`;
