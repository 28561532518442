import React from "react";
import { NavBar } from "./navbar-styles";
import { NavItemLinks } from "./nav-links";
import { NavProps } from "./nav-bar-types";

export const Navigation = ({ className }: NavProps) => (
  <>
    <NavBar className={className}>
      <NavItemLinks />
    </NavBar>
  </>
);
