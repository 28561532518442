import React from "react"
import {
  Container,
  LeftTextContainer,
  RightTextContainer,
  LeftTextHeading,
  RightTextHeading,
  Text,
} from "./text-block-content-styles";
import {TextBlockContentProps} from "./text-block-content-types"

export const TextBlockContent = ({
  leftTextHeading,
  leftText,
  rightTextHeading,
  rightText
}: TextBlockContentProps) => {
  return (
    <Container>
      <LeftTextContainer>
        <LeftTextHeading>{leftTextHeading}</LeftTextHeading>
        <Text>{leftText}</Text>
      </LeftTextContainer>
      <RightTextContainer>
        <RightTextHeading>{rightTextHeading}</RightTextHeading>
        <Text>{rightText}</Text>
      </RightTextContainer>
    </Container>
  );
};


