import React from "react";
import { logo } from "../../assets/images";
import {
  HeaderButton,
  NavLinks,
  NavLinkItem as NavLink,
  LogoContainer,
  Logo,
  MenuItem,
} from "./navbar-styles";

export const NavItemLinks = () => (
  <>
    <LogoContainer href="/">
      <Logo src={logo} alt="Blaq byte logo" />
    </LogoContainer>
    <NavLinks>
      <NavLink to="/home">Home</NavLink>
      <NavLink to="/about">About</NavLink>
      <MenuItem href="https://calendly.com/blaqbyte/1-1-get-into-tech-mentorship?month=2023-02" target="_blank">
        Book a consultation
      </MenuItem>
      <NavLink to="/contact">Contact</NavLink>
      {/* <NavLink to="/partner">Partner</NavLink> */}
      <HeaderButton
        href="https://docs.google.com/forms/d/e/1FAIpQLSc9ns9a3GUxYOArffsi0PbJAKZEwCGUUU6nwtl_O4ngBl66dw/viewform"
        target="_blank"
      >
        Join community
      </HeaderButton>
    </NavLinks>
  </>
);
