import React from "react";
import {
  Container,
  CardContainer,
  CardIcon,
  TitleText,
  DescriptionText,
  SectionHeading,
} from "./card-styles";
import { CardProps } from "./card-types";

export const Card = ({ cards, sectionHeading, textStyle}: CardProps) => {
  return (
    <>
      <SectionHeading style={{ color: `${textStyle}` }}>
        {sectionHeading}
      </SectionHeading>
      <Container>
        {cards.map((card) => (
          <>
            <CardContainer>
              <CardIcon src={card.iconSrc} alt={card.iconAltText} />
              <TitleText style={{ color: `${textStyle}` }}>
                {card.title}{" "}
              </TitleText>
              <DescriptionText>{card.description} </DescriptionText>
            </CardContainer>
          </>
        ))}
      </Container>
    </>
  );
};
