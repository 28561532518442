import { ISocialIcon } from "./social-media-types";
import {
  SocialMediaIconContainer,
  SocialMediaIconLink,
  SocialMediaIcon,
} from "./social-media-styles";

export const SocialMediaLinks = ({
  clickableLink,
  imageSrc,
  altText,
}: ISocialIcon) => (

    <SocialMediaIconContainer>
      <SocialMediaIconLink href={clickableLink} target="_blank">
        <SocialMediaIcon src={imageSrc} alt={altText} />
      </SocialMediaIconLink>
     </SocialMediaIconContainer>
);
