import React from "react";
import { valueCards } from "../app/app-helpers";
import { aboutPageImage } from "../assets/images";
import { AboutPage } from "../templates/about-page";

export const About = () => {
  const firstText = `Recognising the under-representation of diverse groups in the technology industry and feeling a need for more action, we founded Blaq Byte, a social enterprise that seeks to promote innovation through diversity and help individuals from under-represented groups achieve their full potential in tech careers.`;
  const secondText = `The Social Enterprise Empowers Under-Represented Groups in Tech with real time progress tracking, community support, and career switching courses, we are dedicated to bridging the diversity gap in the tech industry.`;
  const thirdText = `We invite you to join our movement and become one of the future leaders in the UK's diverse tech workforce.`;
  const rightText = `Our goal at Blaq Byte is to increase diversity in the tech workforce and create a more inclusive future for everyone. We are dedicated to our one mission: to fuel the next generation of tech leaders and drive change in the industry within the UK. We accomplish this by developing programs, sharing resources, and providing access to some of the world's most innovative companies for ethnic minorities, women, LGBTQ+ and non-binary.`;

  return (
    <AboutPage
      bannerText="About us"
      textHeading="Unlock Your Potential with Blaq Byte"
      sectionHeading="Our Values"
      firstText={firstText}
      secondText={secondText}
      thirdText={thirdText}
      cards={valueCards}
      imageSrc={aboutPageImage}
      leftTextHeading="Our Mission"
      leftText="At BlaqByte our mission is to empower under-represented groups, fuelling the next generation of tech leaders and driving change in the industry."
      rightText={rightText}
    />
  );
};
