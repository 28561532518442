import { ISocialIcon } from "../components/social-media-links/social-media-types";
import {
  instagram,
  twitter,
  linkedin,
  youtube,
  diversifyValue,
  accessValue,
  empowermentValue,
  leadershipValue,
  plug,
  donations,
  send,
  service,
  podcast
} from "../assets/images";
import { ICard } from "../components/card/card-types";

export const socialMediaIcons: ISocialIcon[] = [
  {
    clickableLink: "https://www.instagram.com/blaqbyte",
    imageSrc: instagram,
    altText: "Instagram/",
  },
  {
    clickableLink: "https://www.twitter.com/blaqbyte",
    imageSrc: twitter,
    altText: "Twitter",
  },
  {
    clickableLink: "https://www.linkedin.com/in/blaq-byte-9aaba3261/",
    imageSrc: linkedin,
    altText: "LinkedIn",
  },
  {
    clickableLink: "https://www.youtube.com/@blaqbyte",
    imageSrc: youtube,
    altText: "Youtube",
  },
  {
    clickableLink: "https://www.buzzsprout.com/2115341",
    imageSrc: podcast,
    altText: "Podcast",
  },
];

export const valueCards: ICard[] = [
  {
    title: "Improving access",
    iconSrc: accessValue,
    iconAltText: "key icon",
  },
  {
    title: "Diversifying the workforce",
    iconSrc: diversifyValue,
    iconAltText: "workforce diversity icon",
  },
  {
    title: "Empowerment",
    iconSrc: empowermentValue,
    iconAltText: "empowerment icon",
  },
  {
    title: "Fostering leaders",
    iconSrc: leadershipValue,
    iconAltText: "Fostering leaders",
  },
];

export const supportCards: ICard[] = [
  {
    title: `Donate your services`,
    iconSrc: service,
    iconAltText: "Donate your services and products icon",
  },
  {
    title: "Contribute",
    iconSrc: send,
    iconAltText: "Contribute to our programs",
  },
  {
    title: "Power our work",
    iconSrc: plug,
    iconAltText: "Power our work",
  },
  {
    title: "Donations",
    iconSrc: donations,
    iconAltText: "Donations icon",
  },
];
