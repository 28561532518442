import React from "react";
import { socialMediaIcons } from "../app/app-helpers";
import { ContactFormPage } from "../templates/contact-form-page-content/content";

export const Contact = () => (
  <>
    <ContactFormPage
      bannerText="Contact Us"
      textHeading="Contact Us"
      textColor="white"
      textPadding="0 45px"
      subContentText="Some relevant blaq byte contact us copy goes here"
      formHeading="Send us a message"
      formSubheading="A member of the team will be in touch within 24 hours"
      formName="Contact Form"
      firstPlaceholder="Full Name"
      secondPlaceholder="Email Address"
      formButtonText="Send"
      socialMediaIcons={socialMediaIcons}
      textAreaPlaceholder="Send us a message"
    />
  </>
);
