import React, { useRef } from "react";
import {
  FormContainer,
  FormHeading,
  FormSubheading,
  Fieldset,
  Legend,
  Input,
  SubmitButton,
  FormSuccessContainer,
  SubmitSuccessButton,
  TwitterIcon,
} from "./form-styles";
import { useForm } from "@formspree/react";
import { FormProps } from "./form-types";
import { toast } from "react-toastify";
import { Toaster } from "../toaster/toaster";
import { stringify } from "qs";
import { twitter2 } from "../../assets/images";

interface TweetParams {
  text: string;
  hashtags: string;
  via: string;
  url: string;
}

export const Form = ({
  formHeading,
  formSubheading,
  formName,
  firstPlaceholder,
  secondPlaceholder,
  formButtonText,
}: FormProps) => {
  const tweetText =
    "Exciting news! I just joined Blaq Byte to get free resources to break into tech. You can join this inclusive community too!";
  const hashtags = "careerswitch,diversityintech";
  const twitterUsername = "blaqbyte";
  const url = "https://www.blaqbyte.com";
  const tweetUrl: string = `https://twitter.com/intent/tweet?${stringify({
    text: tweetText,
    hashtags: hashtags,
    via: twitterUsername,
    url: url,
  } as TweetParams)}`;

  const [state, handleSubmit] = useForm("xoqzenwb");
  const isMounted = useRef(false);

  const notify = () => {
    if (!isMounted.current) {
      toast.info("Successfully submitted!", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      isMounted.current = true;
    }
  };

  if (state.succeeded) {
    notify();
    return (
      <FormSuccessContainer>
        <FormHeading>
          You've successfully signed up to our mailing list
        </FormHeading>
        <FormSubheading>
          You're one step closer to levelling up your career in tech!
        </FormSubheading>
        <SubmitSuccessButton
          href={tweetUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          Share this on Twitter{" "}
          <TwitterIcon src={twitter2} alt="twitter icon" />
        </SubmitSuccessButton>
        <Toaster />
      </FormSuccessContainer>
    );
  }
  return (
    <FormContainer onSubmit={handleSubmit}>
      <FormHeading>{formHeading}</FormHeading>
      <FormSubheading>{formSubheading}</FormSubheading>
      <Fieldset>
        <Legend>{formName}</Legend>
        <Input name="fullname" type="text" placeholder={firstPlaceholder} />
        <Input name="email" type="email" placeholder={secondPlaceholder} />
        <SubmitButton type="submit">{formButtonText}</SubmitButton>
      </Fieldset>
    </FormContainer>
  );
};
