import styled from "styled-components";

export const SectionContainer = styled.div`
  display: flex;
  padding: 24px 48px;
  gap: 24px;
  color:#031f48;

  @media screen and (max-width: 936px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const TextContainer = styled.div`
  display: block;
  width: 50%;

  @media screen and (max-width: 936px) {
    width: 100%;
  }
`;

export const TextHeading = styled.h1`
  font-size: 2em;
  font-weight: 400px;

  @media screen and (max-width: 1061px) {
    font-size: 32px;
  }
`;

export const TextSubheading = styled.p`
  font-size: 24px;
  margin-bottom: 12px;
  
  @media screen and (max-width: 1061px) {
    font-size: 20px;
  }
`;

export const Image = styled.img`
  width: 50%;

  @media screen and (max-width: 936px) {
    width: 100%;
  }
`;