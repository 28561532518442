import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;

  @media screen and (max-width: 900px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 590px) {
    display: block;
  }
`;
export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 590px) {
    width: 100%;
  }
`;

export const CardIcon = styled.img`
  width: 100px;
  height: 100px;
`;

export const TitleText = styled.h2`
  color: #031f48;
`;

export const DescriptionText = styled.p`
  color: #031f48;
`;

export const SectionHeading = styled.h2`
  display: flex;
  justify-content: center;
  font-size: 35px;
  margin: 0;
  padding: 24px;
  color: #031f48;
`;
