import styled from "styled-components";
import { mainContent } from "../../assets/images";

export const SectionOne = styled.div`
  background-image: url(${mainContent});
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  padding: 42px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
`;

export const SectionOneTextContent = styled.p`
  margin: 12px 0;
  font-size: 62px;
  font-weight: 700;
  color: #fff;
  width: 65%;

  @media screen and (max-width: 1035px) {
    font-size: 62px;
    width: 100%;
  }

  @media screen and (max-width: 610px) {
    font-size: 42px;
  }

  @media screen and (max-width: 390px) {
    font-size: 32px;
    grid-template-columns: 1fr;
  }
`;

export const SectionOneSocialMediaLinks = styled.div`
  display: flex;
`;
