import React from "react";
import { SectionContainer, TextContainer} from "./content-styles";
import { ContactForm } from "../../components/contact-us-form";
import { ContactFormPageProps } from "./content-types";
import { Banner } from "../../components/banner";

export const ContactFormPage = ({
  bannerText,
  formHeading,
  formSubheading,
  formName,
  firstPlaceholder,
  secondPlaceholder,
  formButtonText,
  textAreaPlaceholder,
  socialMediaIcons,
}: ContactFormPageProps) => {
  return (
    <>
      <Banner bannerText={bannerText} socialMediaIcons={socialMediaIcons} />
      <SectionContainer>
        <TextContainer/>
        <ContactForm
          textAreaPlaceholder={textAreaPlaceholder}
          formHeading={formHeading}
          formSubheading={formSubheading}
          formName={formName}
          firstPlaceholder={firstPlaceholder}
          secondPlaceholder={secondPlaceholder}
          formButtonText={formButtonText}
        />
      </SectionContainer>
    </>
  );
};
