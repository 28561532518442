import styled from "styled-components";

const Container = styled.div`
  width: 100%
  padding: 48px;
  display: flex;
  margin-bottom: 24px;

  
  @media screen and (max-width:705px){
    display:block;


  }
`;

const LeftTextContainer = styled.div`
  padding: 48px;
  display: flex;
  flex-direction: column;
  color: #fff;
  background-color: #c65707;

  @media screen and (max-width: 705px) {
    padding: 24px 48px;
  }
`;

const RightTextContainer = styled.div`
  padding: 48px;
  display: flex;
  flex-direction: column;
  background-color: #ededec;
  justify-content: center;
  color: #031f48;
`;

const TextContainer = styled.div`
  padding-left: 48px;
  display: flex;
  flex-direction: column;
`;

const LeftTextHeading = styled.h2`
margin-bottom:8px;
margin-top:0;
`;
const RightTextHeading = styled.h2`
  display: none;
`;

const Text = styled.p`
  font-size: 20px;
  margin: 0px;


`;

export {
  Container,
  LeftTextContainer,
  RightTextContainer,
  TextContainer,
  LeftTextHeading,
  RightTextHeading,
  Text,
};
