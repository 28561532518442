import styled from "styled-components";

export const FormContainer = styled.form`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ededec;
  border: none;
  width: 50%;


  @media screen and (max-width: 825px) {
    width: 100%;
  }
`;


export const FormSuccessContainer = styled.form`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ededec;
  border: none;
  width: 50%;

  @media screen and (max-width: 825px) {
    width: 100%;
  }
`;

export const FormHeading = styled.h1`
  margin: 0;
`;
export const FormSubheading = styled.p``;

export const Fieldset = styled.fieldset`
  all: unset;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

export const Legend = styled.legend`
  visibility: hidden;
`;
export const Input = styled.input`
  height: 32px;
  margin-bottom: 8px;
  border: none;
  border-radius: 4px;
  padding: 4px;
  font-size: 14px;
`;

export const TextArea = styled.textarea`
  height: 100px;
  resize: none;
  font-family: inherit;
  font-size: 14px;
  margin-bottom: 8px;
  border: none;
  border-radius: 4px;
  padding: 4px;
`;
export const SubmitButton = styled.button`
  width: fit-content;
  height: fit-content;
  padding: 8px 20px;
  font-weight: 400px;
  font-family: inherit;
  border-radius: 4px;
  border: none;
  color: white;
  align-self: flex-end;
  font-size: 16px;
  font-weight: 200px;
  background: #c65707;
  cursor: pointer;

  &:hover {
    background: #d97700;
  }
`;

export const Toaster = styled.div``;
