import React, { useRef } from "react";
import { useForm, ValidationError } from "@formspree/react";
import {
  FormContainer,
  FormHeading,
  FormSubheading,
  Fieldset,
  Legend,
  Input,
  SubmitButton,
  TextArea,
  FormSuccessContainer,
} from "./form-styles";
import { ContactFormProps } from "./form-types";
import { Toaster } from "../toaster/toaster";
import { toast } from "react-toastify";

export const ContactForm = ({
  formHeading,
  formSubheading,
  formName,
  firstPlaceholder,
  secondPlaceholder,
  formButtonText,
  textAreaPlaceholder,
}: ContactFormProps) => {
  const [state, handleSubmit] = useForm("xwkjlrlj");
  const isMounted = useRef(false);

  const notify = () => {
    if (!isMounted.current) {
      toast.info("Successfully submitted!", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      isMounted.current = true;
    }
  };

  if (state.succeeded) {
    notify();
    return (
      <FormSuccessContainer>
        <FormHeading>Thank you for getting in touch!</FormHeading>
        <FormSubheading>
          We appreciate you contacting Blaq Byte and a member of the team will
          get back to you soon.
        </FormSubheading>
        <Toaster />
      </FormSuccessContainer>
    );
  }
  return (
    <FormContainer onSubmit={handleSubmit}>
      <FormHeading>{formHeading}</FormHeading>
      <FormSubheading>{formSubheading}</FormSubheading>
      <Fieldset>
        <Legend>{formName}</Legend>
        <Input name="fullname" type="text" placeholder={firstPlaceholder} />
        <ValidationError field="fullname" errors={state.errors} />
        <Input name="email" type="email" placeholder={secondPlaceholder} />
        <ValidationError field="email" errors={state.errors} />
        <TextArea name="message" placeholder={textAreaPlaceholder} />
        <ValidationError field="message" errors={state.errors} />
        <SubmitButton disabled={state.submitting}>
          {formButtonText}
        </SubmitButton>
      </Fieldset>
    </FormContainer>
  );
};
