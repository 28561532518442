import styled from "styled-components"

export const Container = styled.div`
  background-color: #ededec;
`; 

export const Link = styled.a`
 text-decoration: none;
`

export const MiniBanner = styled.p`
  background-color: #efa976;
  padding: 12px;
  display: flex;
  color: #031f48;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`;