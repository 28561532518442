import React from "react";
import { socialMediaIcons } from "../app/app-helpers";
import { FormContent } from "../templates/form-content/content";

export const Home = () => (
  <FormContent
    bannerText="Empowering diversity in tech. Bridging the gap, building futures."
    textHeading="The most unbiased platform for launching a career in tech"
    subContentText="Find a career path, browse courses, connect with mentors and track your career progress in real time."
    textColor="default"
    formHeading="Subscribe to our mailing list"
    formSubheading="Subscribe to the mailing list to gain access to exclusive resources, training and mentorship"
    formName="Sign up form"
    firstPlaceholder="Full Name"
    secondPlaceholder="Email Address"
    formButtonText="Submit"
    sectionThreeHeading="Get started on your tech career journey"
    socialMediaIcons={socialMediaIcons}
  />
);
