import styled from "styled-components";

export const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  background: #1b1a17;
  color: white;
  font-weight: 600;
`;
export const FooterText = styled.p`
  font-size 14px;
 `;
