import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 48px;
  @media screen and (max-width: 881px) {
    padding: 0;
  }
`;

export const ErrorHeading = styled.h1`
  font-size: 100px;
  margin: 0;
`;

export const ErrorText = styled.h2`
  margin: 0;
  @media screen and (max-width: 881px) {
   display:none;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  @media screen and (max-width: 881px) {
    display: none;
  }
`;

export const RedirectButton = styled.button`
  padding: 8px 20px;
  font-weight: 400px;
  border-radius: 4px;
  margin-right: 12px;
  border: none;
  color: white;
  font-size: 16px;
  background: #c65707;
  cursor: pointer;

  &:hover {
    background: #d97700;
  }
`;

export const Image = styled.img`
@media screen and (max-width: 881px){
  display:none;
}

`
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  padding-left: 100px;

  @media screen and (max-width: 881px) {
    padding:0;
  }
`;

export const ImageContainer = styled.div``

export const ErrorSubText = styled.p`
  @media screen and (max-width: 881px) {
    display: none;
  } ;
`;

export const ErrorMobileText = styled.h2`
  display: none;
  @media screen and (max-width: 881px) {
    display: block;
  }
`;