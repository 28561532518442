import styled from "styled-components";
import { contactPageImage } from "../../assets/images";


export const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
  background-image: url(${contactPageImage});
  height: 500px;
  width: 100%;
`;

export const TextContainer = styled.div`
  display: block;
  width: 50%;
  color: white;

  @media screen and (max-width: 715px) {
    display:none;
  }
`;

export const TextHeading = styled.h1`
  margin: 0;
  font-size: 2em;
  font-weight: 400px;
`;

export const TextSubheading = styled.p`
  font-size: 24px;
`;

