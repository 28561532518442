import React from "react";
import {
  SectionContainer,
  TextContainer,
  TextHeading,
  TextSubheading,
  Image,
} from "./content-styles";
import { PageContentProps } from "./content-types";
import { Banner } from "../../components/banner";

export const PageContent = ({
  bannerText,
  textHeading,
firstText,
secondText,
thirdText,
  socialMediaIcons,
  imageSrc
  
}: PageContentProps) => {
  return (
    <>
      <Banner bannerText={bannerText} socialMediaIcons={socialMediaIcons} />
      <SectionContainer>
        <TextContainer>
          <TextHeading>{textHeading}</TextHeading>
          <TextSubheading>{firstText}</TextSubheading>
          <TextSubheading>{secondText}</TextSubheading>
          <TextSubheading>{thirdText}</TextSubheading>
        </TextContainer>
        <Image src={imageSrc} alt="" />
      </SectionContainer>
    </>
  );
};
