import React from "react";
import {
  SectionOne,
  SectionOneTextContent,
  SectionOneSocialMediaLinks,
} from "./banner-styles";
import { SocialMediaLinks } from "../social-media-links";
import { BannerProps } from "./banner-types";

export const Banner = ({ bannerText, socialMediaIcons }: BannerProps) => {
  return (
    <SectionOne>
      <SectionOneTextContent>{bannerText}</SectionOneTextContent>
      <SectionOneSocialMediaLinks>
        {socialMediaIcons?.map((icon, key) => (
          <SocialMediaLinks
            key={key}
            clickableLink={icon.clickableLink}
            imageSrc={icon.imageSrc}
            altText={icon.altText}
          />
        ))}
      </SectionOneSocialMediaLinks>
    </SectionOne>
  );
};
