import React from "react";
import { SectionContainer} from "./content-styles";
import { ContentProps } from "./content-types";
import { Form } from "../../components/sign-up-form";
import { Banner } from "../../components/banner";
import { TextSection } from "../../components/text-section";


export const FormContent = ({
  textHeading,
  subContentText,
  formHeading,
  formSubheading,
  formName,
  firstPlaceholder,
  secondPlaceholder,
  formButtonText,
  socialMediaIcons,
  bannerText,
  textColor,
}: ContentProps) => {
  return (
    <>
      <Banner bannerText={bannerText} socialMediaIcons={socialMediaIcons} />
      <SectionContainer>
     
          <TextSection
            textColor={textColor}
            textHeading={textHeading}
            subContentText={subContentText}
          />
          <Form
            formHeading={formHeading}
            formSubheading={formSubheading}
            formName={formName}
            firstPlaceholder={firstPlaceholder}
            secondPlaceholder={secondPlaceholder}
            formButtonText={formButtonText}
          />
      </SectionContainer>
    </>
  );
};
