import styled from "styled-components";

export const SocialMediaIconOuterContainer = styled.div`
  display: flex;
  margin-right: 12px;
`;
export const SocialMediaIconContainer = styled.div`
  display: block;
  margin-right: 12px;
    width: 4em;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;
export const SocialMediaIconLink = styled.a`
  margin: 12px;
  width: fit-content;
`;
export const SocialMediaIcon = styled.img`
  display: flex;
  @media screen and (max-width: 390px) {
    width: 48px;
  }
`;
