import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
`;
export const Span = styled.span`
  width: 48px;
  height: 48px;
  border: 4px solid #c65707;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  &::after,
  &::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    background: #ff3d00;
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
  &::before {
    left: auto;
    top: auto;
    right: 0;
    bottom: 0;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export const Image = styled.img``;
