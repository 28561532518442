import styled from "styled-components";

export const FormContainer = styled.form`
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ededec;
  border: none;
  width: 50%;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);

  @media screen and (max-width: 826px) {
    width: 100%;
  }
`;

export const FormSuccessContainer = styled.form`
  border: 0.5px solid #c65707;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ededec;
  width: 50%;

  @media screen and (max-width: 825px) {
    width: 100%;
  }
`;

export const FormHeading = styled.h1`
  margin: 0;

  @media screen and (max-width: 825px) {
    font-size: 23px;
  }
`;
export const FormSubheading = styled.p``;

export const Fieldset = styled.fieldset`
  all: unset;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

export const Legend = styled.legend`
  visibility: hidden;
`;
export const Input = styled.input`
  width: 100%;
  height: 32px;
  margin-bottom: 8px;
  border: none;
  font-size: 14px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 0 0 12px;
`;
export const SubmitButton = styled.button`
  width: fit-content;
  height: fit-content;
  padding: 8px 20px;
  font-weight: 400px;
  border-radius: 4px;
  border: none;
  color: white;
  font-size: 16px;
  font-family: inherit;
  background: #c65707;
  cursor: pointer;
  align-self: flex-end;

  &:hover {
    background: #d97700;
  }
`;

export const SubmitSuccessButton = styled.a`
  text-decoration: none;
  width: fit-content;
  height: fit-content;
  padding: 8px 20px;
  font-weight: 400px;
  border-radius: 4px;
  border: none;
  color: white;
  font-size: 16px;
  background: #c65707;
  cursor: pointer;
  align-self: flex-start;
  display: flex;

  &:hover {
    background: #d97700;
  }
`;
export const Toaster = styled.div``;

export const TwitterIcon = styled.img`
  width: 16px;
  margin-left: 8px;
`;
