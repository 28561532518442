import React from "react";
import { FooterProps } from "./footer-types";
import { FooterContainer, FooterText } from "./footer-styles";

export const Footer = ({ footerText }: FooterProps) => {
  return (
    <FooterContainer>
      <FooterText>{footerText}</FooterText>
    </FooterContainer>
  );
};
